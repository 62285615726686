import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Routes, Route } from "react-router-dom";
import TerabhAI from "./pages/TerabhAI";
import Company from "./pages/Company";
import TechnicalDetails from './pages/TechnicalDetails';
import Userguide from './pages/Userguide';
import Plugins_Expertise from "./pages/Plugins_Expertise";
import ScrollToTop from "./Components/ScrollToTop";

function App() {
  const isDesktop = useMediaQuery({ minWidth: 1224 });
  const isMobileOrTablet = useMediaQuery({ maxWidth: 1224 });

  const containerStyle = {
    width: isMobileOrTablet ? '1024px' : '100%',
    margin: '0 auto',
    overflowX: 'auto'
  };

  return (
    <div className="App">
      <div style={containerStyle}>

        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<TerabhAI />} />
          <Route exact path="/company" element={<Company />} />
          <Route exact path="/technical_details" element={<TechnicalDetails />} />
          <Route exact path="/user_guide" element={<Userguide />} />
          <Route exact path="/Plugins_Expertise" element={<Plugins_Expertise />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
