import React from 'react';
import logo from "../assets/logo.png"
import vector4 from '../assets/vector4.svg';
import { Link } from 'react-router-dom';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react'

const Navbar = () => {
    return (
        <div className={`fixed z-10 flex mt-6 w-[900px] h-[83px] text-base text-txt font-inter `}>
            <div className="h-[97.61%] w-[99.77%] top-[1.19%] right-[0.11%] bottom-[1.19%] left-[0.11%] rounded-81xl [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.76),_rgba(255,_255,_255,_0.2))] opacity-[0.2]" />
            <div className="absolute w-[calc(100%_-_3.8px)] top-[2px] right-[1.8px] left-[2px] rounded-81xl bg-color-2 h-[79.8px]">
                <div className="absolute top-[31.8px] left-[calc(50%_-_167.1px)] flex flex-row items-center justify-evenly py-0 px-3 gap-[40px] text-white">
                    <div className=" flex w-[44.6px] relative h-4">
                        <Link className="absolute top-[0px] left-[0px] leading-[16px] flex items-center w-[45px] h-4 cursor-pointer no-underline text-white" to='/'>
                            Home
                        </Link>
                    </div>
                    <div className=" flex w-16 relative h-4">
                        <Link className="absolute top-[-0.2px] left-[0px] leading-[16px] flex items-center w-16 h-4 cursor-pointer no-underline text-white" to="/">
                            Webapp <i className="arrow fa-solid fa-arrow-up" style={{ rotate: "45deg", marginLeft: "3px" }}></i>
                        </Link>
                    </div>
                    <div className="flex w-[79px] relative h-4">
                        <div className="absolute top-[-5px] left-[-0.1px] leading-[16px] flex items-center w-[100px] h-[26px] cursor-pointer no-underline text-white" to="/">
                            <Menu>
                                <MenuButton color="white" backgroundColor="rgb(37 37 37 / var(--tw-bg-opacity))" fontSize="16px" fontFamily="Inter">
                                    Actions <i className="fa-solid fa-angle-down"></i>
                                </MenuButton>
                                <MenuList>
                                    <MenuItem borderTopRadius="7px" backgroundColor="rgb(231 106 78 / var(--tw-text-opacity))" fontSize="16px" fontFamily="Inter" paddingY="8px"><Link to='/user_guide' style={{ textDecoration: "none", color: "white" }}>User Guide</Link></MenuItem>
                                    <MenuItem backgroundColor="rgb(231 106 78 / var(--tw-text-opacity))" fontSize="16px" fontFamily="Inter" paddingY="8px"><Link to='/Plugins_Expertise' style={{ textDecoration: "none", color: "white" }}>Plugins and Expertise</Link></MenuItem>
                                    <MenuItem backgroundColor="rgb(231 106 78 / var(--tw-text-opacity))" fontSize="16px" fontFamily="Inter" paddingY="8px" borderBottomRadius="7px" cursor="pointer"><Link to='/technical_details' style={{ textDecoration: "none", color: "white" }}>Technical Details</Link></MenuItem>
                                </MenuList>
                            </Menu>
                        </div>
                    </div>
                    <Link className="relative leading-[16px] flex items-center h-4 shrink-0 cursor-pointer no-underline text-white" to="/company">
                        Company
                    </Link>
                </div>
                <div className="absolute top-[calc(50%_-_25.9px)] right-[13.9px] rounded-29xl w-[157.7px] h-[51.8px]">
                    <div className="absolute top-[-1.2px] left-[1.4px] rounded-29xl [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.2),_rgba(255,_255,_255,_0)),_#e76a4e] w-[156px] h-[52px] overflow-hidden flex flex-row items-center justify-center py-1.5 px-3 box-border">
                        <div className="relative font-medium text-white">Download App</div>
                    </div>
                </div>
                <div className="absolute top-[22.8px] left-[30px] w-[158px] h-9 text-6xl">
                    <div className="absolute top-[2px] left-[45px] tracking-[0.02em] font-semibold">
                        <span className='text-white'>Terabh</span>
                        <span className="text-color">AI</span>
                    </div>
                    <img
                        className="absolute h-full w-[22.78%] top-[0%] right-[77.22%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                        alt=""
                        src={vector4}
                    />
                    <img
                        className="absolute top-[4px] left-[4px] w-[29px] h-7 object-cover mix-blend-lighten"
                        alt=""
                        src={logo}
                    />
                </div>
            </div>
        </div>
    );
}

export default Navbar;
