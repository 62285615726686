import React from 'react';
import vector from '../assets/vector.svg';

const C2 = () => {
  return (
    <div className='flex flex-row justify-around'>
      <span style={{ backgroundColor: "rgb(231 106 78)", padding: "14px", borderRadius: "50px", fontSize: "18px", alignContent: "center", width: "160px", justifyContent: "space-around",display:"flex",boxShadow:"0px 0px 6px 6px rgba(231, 106, 78, 0.35)" }}>
                            <span className='flex'>Google Play</span>
                            <span className="fa-brands fa-google-play flex"></span>
                        </span>
                        <span style={{ backgroundColor: "#212121", padding: "14px", borderRadius: "50px", fontSize: "18px", alignContent: "center", width: "160px", justifyContent: "space-around",display:"flex", border:"2px solid rgba(245, 245, 245, 0.4)"}}>
                        <span className='flex'>Web App</span>
                        <span className="fa-solid fa-arrow-up-right-from-square"></span>
                        </span>
    </div>
  );
}

export default C2;
