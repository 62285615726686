import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import checkList from "../assets/checkList.png";
import checkList2 from "../assets/checkList2.png";
import Btn from "../Components/Btn";
import notebook from "../assets/notebook.png";
import Ledger from "../assets/Ledger.png";
import gmailpng from "../assets/gmailpng.png";
import information from "../assets/information.png";
import Footer from "../Components/Footer"


const Plugins_Expertise = () => {

  const [formData, setFormData] = useState({
    name: "",
    category: "",
    desc: "",
    email:""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
  };


  return (
    <div className="bg-bg font-inter" style={{ padding: "40px" }}>
      <div
        style={{
          display: "flex",
          height: "130px",
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Navbar />
      </div>
      <div style={styles.container}>
        <h1 style={{ fontSize: "50px", textAlign: "center" }}>Plugins and Expertise</h1>
        <p style={{ fontSize: "20px", marginBottom: "50px" }}>
          Most of our plugins at the moment are focused on productivity. This
          aligns with our mission to free up time and mind space for our users.
          We will keep adding more plugins with time. The sweetest part about
          our plugins is that all of them work through just your text and audio.
          From writing notes to going through important mails, bhAI takes care
          of it all.
        </p>
        <div className="flex flex-col gap-3">
          <div className="flex flex-row justify-between">
            <div className="w-[28%] p-4 items-center" style={{ backgroundColor: "#171717", borderRadius: "15px" }}>
              <h1 style={{ fontSize: "40px", paddingLeft: "1rem", paddingRight: "1rem" }}>Tasks</h1>
              <p style={{ fontSize: "18px", paddingLeft: "1rem", paddingRight: "1rem" }}>
                Tasks is a todo plugin which with all the basic functionalities
                of a todo - creating todos, marking them done, setting
                priorities and labels and more.
              </p>
              <div className="w-full items-end">
                <img src={checkList} alt="" className="w-full" />
                <img
                  src={checkList2}
                  alt=""
                  className="w-full mt-[-100%] relative"
                />
              </div>
            </div>
            <div className="w-[68%] flex flex-col gap-3">
              <div
                className="p-4 flex flex-row"
                style={{ backgroundColor: "#171717", borderRadius: "15px" }}
              >
                <div className="w-[68%]">
              
                  <h1 style={styles.title}>Notes</h1>
                  <p style={{ fontSize: "18px", paddingLeft: "1rem", paddingRight: "1rem" }}>
                    Writing, appending, updating, deleting notes have never been
                    easier. Write in plain text and bhAI will create beautiful
                    markdown notes for you.
                  </p>
                </div>
                <div className="w-[32%]" style={{ alignContent: "end" }}>
                  <img src={notebook} alt="" className="w-full" />
                </div>
              </div>
              <div className="flex flex-row justify-between bg-bg w-full ">
                <div
                  className="w-[46%] p-4"
                  style={{ backgroundColor: "#171717", borderRadius: "15px" }}
                >
                  <h1 style={styles.title}>Gmail</h1>
                  <p style={{ fontSize: "18px", paddingLeft: "1rem", paddingRight: "1rem" }}>
                    Ever got tired of going through 20 mails only to find that
                    only 2 were relevant? The gmail plugin does this and much
                    more.
                  </p>
                  <div className="flex flex-row justify-between">
                    <div className="flex w-[60%]">
                      <img src={gmailpng} alt="" className="w-full" />
                    </div>
                  </div>
                </div>
                <div
                  className="w-[47%] py-4 px-2"
                  style={{
                    backgroundColor: "#171717",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "15px"
                  }}
                >
                 
                  <div style={{ marginTop: "40px", width: "80%", paddingLeft: "1rem", paddingRight: "1rem" }}>
                    Ever got tired of going through 20 mails only to find that
                    only 2 were relevant? The gmail plugin does this and much
                    more.
                  </div>
                  <div className="flex flex-row">
                    <div className="flex w-[40%]">
                      {" "}
                      <span
                        style={{
                          fontSize: "40px",
                          padding: "5px",
                          alignSelf: "center",
                        }}
                      >
                        Ledger
                      </span>
                    </div>
                    <div style={{ float: "right", width: "60%" }}>
                      <img src={Ledger} alt="" className="w-full" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between ">
            <div
              className="flex flex-row w-[63.3%] p-4 "
              style={{ backgroundColor: "#171717", borderRadius: "15px" }}
            >
              <div className="w-[65%]">
                <h1 style={styles.title}>
                  Information
                </h1>
                <p style={{ fontSize: "18px", paddingLeft: "1rem", paddingRight: "1rem" }}>
                  Get all the information relevant to you with our information
                  plugin. It’s equipped with wiki, search and scrapping tools
                  under the hood
                </p>

               
              </div>
              <div className="w-[35%]" style={{ alignContent: "end" }}>
                <img src={information} alt="" className="w-full" />
              </div>
            </div>
            <div
              className="w-[30.6%] p-4"
              style={{ backgroundColor: "#171717", borderRadius: "15px" }}
            >
              <h1 style={styles.title}>Expertise</h1>
              <p style={{ fontSize: "18px", paddingLeft: "1rem", paddingRight: "1rem" }}>
                You can give bhAI any of these expertise and with the tap of a
                button, your assistant will become an expert of that subject
                matter.
              </p>
              <div className="flex flex-wrap justify-between">
                <span className="my-4 mx-1 flex">
                  <Btn data="Law" />
                </span>
                <span className="my-4 mx-1 flex">
                  <Btn data="India (coming soon)" />
                </span>
                <span className="my-4 mx-1 flex">
                  <Btn data="Health" />
                </span>
                <span className="my-4 mx-1 flex">
                  <Btn data="Finance (coming soon)" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex px-4 mt-4 pb-6 flex-col pt-4"
          style={{ backgroundColor: "#171717", borderRadius: "15px" }}>
          <div style={{ fontSize: "40px", padding: "5px", textAlign: "center" }}>
            Plugin and Expert Request
          </div>
          <p style={styles.txt}>
            We are actively working on new plugins. Got a plugin request? Just
            put down a request below and we will add it asap.
          </p>
          <div className="flex w-full m-4 flex-row justify-around">
            <div className="flex w-[30%] flex-col gap-8">
              <div className="flex flex-col gap-2">
                <div>Name :</div>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  style={styles.input}
                />
              </div>
              <div className="flex flex-col gap-2">
                <div>Email :</div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={styles.input}
                />
              </div>
              <div className="flex flex-col gap-2">
                <div>Category :</div>
                <input
                  type="text"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  style={styles.input}
                />
              </div>
            </div>
            <div className="flex w-[60%]">
              <div className="flex w-full flex-col gap-2">
                <div>Description :</div>
                <textarea
                  type="text"
                  name="desc"
                  value={formData.desc}
                  onChange={handleChange}
                  style={{
                    borderRadius: "20px",
                    width: "90%",
                    height: "210px",
                    paddingLeft:"12px",
                    paddingTop:"8px"
                  }}
                />
              </div>
            </div>
          </div>

          <div className="w-full flex justify-center">
            <span className="my-4 px-5 flex" onClick={handleSubmit}>
              <span style={{ backgroundColor: "rgb(231 106 78 / var(--tw-text-opacity))", borderRadius: "20px", paddingLeft: "40px", paddingRight: "40px", paddingTop: "4px", paddingBottom: "4px", background: "rgb(231 106 78)" }}>
                <span style={{ marginRight: "5px", fontSize: "20px" }}>Go</span><i class="fa-solid fa-arrow-up-right-from-square"></i>
              </span>
            </span>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Helvetica",
    color: "white",
    padding: "50px",
    textAlign: "justify",
  },
  title: { 
    fontSize: "40px", 
    paddingLeft: "20px", 
    paddingRight: "16px" 
  },
  txt:{ 
    fontSize: "18px", 
    paddingLeft: "1rem", 
    paddingRight: "1rem" 
  },
  input:{ 
    borderRadius: "20px", 
    height: "30px",
    paddingLeft:"14px"
  }
};

export default Plugins_Expertise;
