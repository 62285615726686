import React from 'react';

const Btn = (props) => {
    const {data} = props;
  return (
    <span style={{backgroundColor:"rgb(231 106 78 / var(--tw-text-opacity))", borderRadius:"20px",paddingLeft:"10px",paddingRight:"10px",paddingTop:"4px", paddingBottom:"4px", background:"rgb(231 106 78)"}}>
        <span style={{marginRight:"5px",fontSize:"20px"}}>{data}</span><i class="fa-solid fa-arrow-up-right-from-square"></i>
    </span>
  );
}

export default Btn;
