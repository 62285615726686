import React from 'react';
import phoneScreen from '../assets/phoneScreen.png';
import signu2p from '../assets/signu2p.png';
const C11 = () => {
  return (
    <div className='w-full justify-center flex mt-48 font-inter h-[410px]'>
      <div className="flex flex-row w-[80%]" style={{ backgroundImage: "linear-gradient(to bottom right, rgb(28 28 28 ), rgb(231 106 78))", borderRadius: "32px", justifyContent: "space-around", paddingRight: "50px", paddingLeft: "50px" }}>
        <div style={{ display: "flex", width: "60%", flexDirection: "column" }}>
          <p style={{ fontSize: "56px", lineHeight: "0px", fontWeight: "600" }}> bh<span className='text-color'>AI</span></p>
          <p className="m-0" style={{ fontSize: "18px" }} >is your friendly neighbourhood personal assistant</p>
          <p className="m-0" style={{ fontSize: "18px" }}>gives you personalized responses</p>
          <p className="m-0" style={{ fontSize: "18px" }}>writes emails for you</p>
          <p className="m-0" style={{ fontSize: "18px" }}>communicates on your behalf </p>
          <p className="m-0" style={{ fontSize: "18px" }}>creates your personal website</p>
          <p className="m-0" style={{ fontSize: "18px" }}>manages your tasks and events</p>
          <p className="m-0" style={{ fontSize: "18px" }}>searches the web for you</p>
          <p className="m-0" style={{ fontSize: "18px" }}>sets reminders for you</p>
          <p className="m-0" style={{ fontSize: "18px" }}>manages your notes</p>

          <span style={{ backgroundColor: "#171717", marginTop: "22px", padding: "14px", borderRadius: "50px", fontSize: "18px", alignContent: "center", width: "160px", justifyContent: "space-around", display: "flex", boxShadow: "0px 0px 6px 6px rgba(17, 17, 17, 0.35)" }}>
            <span className='flex'>Google Play</span>
            <span className="fa-brands fa-google-play flex"></span>
          </span>
        </div>
        <div style={{ display: "flex", width: "30%", marginTop: "-70px", flexDirection: "column", position: "relative" }}>
          <img src={phoneScreen} alt='' width="100%" />
          <img src={signu2p} width="100%" alt='' style={{ position: "absolute", marginTop: "2rem" }} />
        </div>
      </div>
    </div>
  );
}

export default C11;