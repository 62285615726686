import React from 'react';
import Group1171279480 from '../assets/Group1171279480.png';
import eye from '../assets/eye.png';
import brain from "../assets/brain.png"
import reveale from '../assets/reveale.png'


const Control_Assistant = () => {
  return (
    <div style={styles.container}>
      <h1 style={{ textAlign: "center", fontSize: "53px" }}>Control your assistant using <span className='text-color'>states</span></h1>

      <div style={{ display: "flex", flexDirection: "row", marginTop: "100px", justifyContent: "space-between", marginBottom: "80px" }}>

        <div style={{ width: "35%" }}>
          <img src={Group1171279480} alt="phoneScreen" style={{ width: "100%" }} />
          <div style={{ position: "relative", backgroundImage: "linear-gradient(rgba(28, 28, 28, 0),rgba(28, 28, 28, 1))", width: "100%", top: "-35%", height: "35%" }}></div>
        </div>
        <div style={{ display: "flex", width: "55%", flexDirection: "column", justifyContent: "space-arund"}}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={eye} alt="eye" style={{ width: "50px", height: "50px" }} />
            <h1 style={styles.subheading}>Eye</h1>
          </div>
          <p style={styles.paragraph}>
            messages in ”see” are currently being seen by bhAI</p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={brain} alt="persist" style={{ width: "50px", height: "50px" }} />
            <h1 style={styles.subheading}>Persist</h1>
          </div>
          <p style={styles.paragraph}>
            persist messages are remembered by bhAI</p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={reveale} alt="reveal" style={{ width: "50px", height: "50px" }} />
            <h1 style={styles.subheading}>Revealed</h1>
          </div>
          <p style={styles.paragraph}>
            revealed messages are shared to the world.</p>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    fontFamily: "Inter",
    color: "white",
    paddingLeft: "150px",
    paddingRight: "150px",
    paddingTop: "50px",
    paddingBottom: "50px",
    textAlign: "justify",
  },
  subheading:
  {
    fontSize: "40px",
    marginLeft: "15px",
  },
  paragraph: {
    fontSize: "18px",
    paddingLeft:"16px"
  }
}

export default Control_Assistant;
