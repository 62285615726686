import React, { useState } from "react";
import phoneScreen from "../assets/phoneScreen.png";
import eye from "../assets/eye.png";
import reveal from "../assets/reveale.png";
import brain from "../assets/brain.png";
import bhai from "../assets/group338.png";
import revealed from "../assets/group339.png";
import file from "../assets/solar_folder-with-files-bold.png";
import Navbar from "../Components/Navbar"
import Footer from "../Components/Footer";
import { useMediaQuery } from 'react-responsive';

function Userguide() {
    const [selectedTab, setSelectedTab] = useState('bhai');

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };
    const isMobileOrTablet = useMediaQuery({ maxWidth: 1224 });

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", height: "40px", backgroundColor: "#171717", display: "flex", justifyContent: "center", position: "relative" }}><Navbar />
            </div>
            <div style={styles.container}>

                <div style={{ fontSize: "45px", textAlign: "center", marginBottom: "50px" }}>
                    <h1> User Guide</h1>
                </div>
                <div style={{ display: "flex", marginTop: "80px", justifyContent: "center" }}>
                    <h1 style={{ ...styles.heading, color: "#E76A4E" }}>1. Persona</h1>
                    <h1 style={{ ...styles.heading, color: "white", }}>lization</h1>
                </div>
                <div style={{ display: "flex", flexDirection: isMobileOrTablet ? "column" : "row", justifyContent: "space-between" }}>
                    <div
                        style={{
                            width: isMobileOrTablet ? "100%" : "45%",
                            display: "flex",
                            alignItems: "center",
                            border: "2px solid white",
                        }}
                    >
                        <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/watch?v=86SNSnl0DjM&t=4s"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </div>
                    <div style={{ width: isMobileOrTablet ? "100%" : "45%" }}>
                        <p style={styles.paragraph}>
                            We mean different things by different words. We all have different
                            knowledge bases. Why should we all get the same generic answers?
                            Shouldn’t your answers be crafted for you? Enter personalization.
                            Our smart personalization engine analyses your messages to
                            understand you better. But don’t worry, we don’t store any
                            information without your consent. Though we are actively trying to
                            improve our personalization engine, it might still get something
                            wrong. Or even though it got something right, you might not want
                            bhAI to remember it. We take consent from you for each piece of
                            personal information we store.
                        </p>
                    </div>

                </div>
                <div style={{ display: "flex", marginTop: "160px", justifyContent: "center" }}>
                    <h1 style={{ ...styles.heading, color: "#E76A4E" }}>2. Control</h1>
                    <h1 style={{ ...styles.heading, color: "white", marginLeft: "15px" }}>States</h1>
                </div>
                <p style={styles.paragraph}>
                    We give you the control of bhAI’s memory and your privacy through control states.<br />
                    Click on the control state to see what it does
                </p>
                <div style={{ display: "flex", flexDirection: isMobileOrTablet ? "column" : "row", alignItems: "center", marginTop: "40px", justifyContent: "space-between" }}>
                    <div style={{ width: isMobileOrTablet ? "60%" : "25%" }}>
                        <img src={phoneScreen} alt="phoneScreen" style={{ width: "100%" }} />
                    </div>
                    <div style={{ display: "flex", width: isMobileOrTablet ? "100%" : "65%", flexDirection: "column", justifyContent: "space-arund" }}>



                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img src={eye} alt="eye" style={{ width: "50px", height: "50px" }} />
                            <h1 style={{ fontSize: "40px", color: "#E76A4E", marginLeft: "15px" }}>See :</h1>
                        </div>
                        <p style={styles.paragraph}>
                            This is the short term memory of your assistant. These messages are actively been
                            considered by bhAI during the conversation                    </p>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img src={brain} alt="eye" style={{ width: "50px", height: "50px" }} />
                            <h1 style={{ fontSize: "40px", color: "#E76A4E", marginLeft: "15px" }}>Persist :</h1>
                        </div>
                        <p style={styles.paragraph}>
                            These messages are in the long term memory of your assistant. bhAI does not forget these over time.                    </p>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img src={reveal} alt="eye" style={{ width: "50px", height: "50px" }} />
                            <h1 style={{ fontSize: "40px", color: "#E76A4E", marginLeft: "15px" }}>Reveal :</h1>
                        </div>
                        <p style={styles.paragraph}>
                            These messages become the base for your revealed assistant. Your revealed assistant
                            uses these messages to answer any question asked about you.                    </p>

                    </div>

                </div>

                <div style={{ display: "flex", marginTop: "160px", justifyContent: "center" }}>
                    <h1 style={{ ...styles.heading, color: "#E76A4E" }}>3. Scre</h1>
                    <h1 style={{ ...styles.heading, color: "white", }}>ens</h1>
                </div>

                <div style={{ display: 'flex', margin: '80px', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', borderRadius: '15px', overflow: 'hidden' }}>
                        <h1
                            onClick={() => handleTabClick('bhai')}
                            style={{
                                fontsize: '25px',
                                color: 'black',
                                backgroundColor: selectedTab === 'bhai' ? '#E76A4E' : 'white',
                                padding: '10px 25px',
                                cursor: 'pointer',
                                margin: 0,
                                borderRight: selectedTab === 'reveal' ? '2px solid #E76A4E' : 'none',
                            }}
                        >
                            bhAI
                        </h1>
                        <h1
                            onClick={() => handleTabClick('reveal')}
                            style={{
                                fontsize: '25px',
                                color: 'black',
                                backgroundColor: selectedTab === 'reveal' ? '#E76A4E' : 'white',
                                padding: '10px 25px',
                                cursor: 'pointer',
                                margin: 0,
                            }}
                        >
                            Reveal
                        </h1>
                    </div>

                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ backgroundColor: '#101010', width: '100%', padding: '50px', borderRadius: '100px' }}>
                        {selectedTab === 'bhai' ? (
                            <div style={{ display: 'flex', flexDirection: isMobileOrTablet ? 'column' : 'row', justifyContent: 'space-around' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: isMobileOrTablet ? '100%' : '50%', paddingTop: '50px' }}>
                                    <h1 style={{ ...styles.heading, color: '#E76A4E' }}>bhAI</h1>
                                    <p style={styles.paragraph}>
                                        The screen in which you communicate with bhAI. bhAI knows your personal info,
                                        uses allowed plugins and selected expertise to craft responses and take actions
                                        on your behalf.
                                    </p>
                                </div>
                                <img src={bhai} alt="bhai" style={{ width: isMobileOrTablet ? '100%' : '30%', marginTop: '25px' }} />
                            </div>
                        ) : (
                            <div style={{ display: 'flex', flexDirection: isMobileOrTablet ? 'column' : 'row', justifyContent: 'space-around' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: isMobileOrTablet ? '100%' : '50%', paddingTop: '50px' }}>
                                    <h1 style={{ ...styles.heading, color: '#E76A4E' }}>Reveal</h1>
                                    <p style={styles.paragraph}>
                                        In your revealed screen, you can chat with anyone’s revealed assistant. You can also
                                        test your own revealed assistant to see how it would respond to others.
                                    </p>
                                </div>
                                <img src={revealed} alt="revealed" style={{ width: isMobileOrTablet ? '100%' : '30%', marginTop: '25px' }} />

                            </div>
                        )}
                    </div>
                </div>

                <div>
                    <div style={{ display: "flex", marginTop: "160px", justifyContent: "center" }}>
                        <h1 style={styles.heading}>4. Revealed</h1>
                        <h1 style={{ ...styles.heading, color: "#E76A4E", marginLeft: "5px" }}>Website</h1>
                    </div>
                    <p style={styles.paragraph}>
                        Using your revealed messages, bhAI automatically creates a website for you. You can select any 5 of the 15
                        sections. These are the sections that will be shown in your website. We never put anything on your revealed
                        website without your consent.

                    </p>





                </div>
                <div >
                    <div style={{ display: "flex", marginTop: "160px", justifyContent: "center" }}>
                        <h1 style={{ fontSize: "50px", color: "#E76A4E" }}>5. Multi</h1>
                        <h1 style={{
                            fontSize: "50px",
                        }}>-Modality</h1>

                    </div>
                    <p style={styles.paragraph}>
                        bhAI understands texts - text messages, code files, pdfs, docs etc, audio and images.
                        Exploit the seamless multi-modality in bhAI to get the best experience
                    </p>
                    <div style={{ display: "flex", flexDirection: isMobileOrTablet ? "column" : "row", justifyContent: "space-around", marginbottom: "100px" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: isMobileOrTablet ? "100%" : "20%", backgroundColor: "#101010", margin: "10px", alignItems: "center", borderRadius: "30px", marginbottom: "100px" }}>
                            <div style={{ backgroundColor: "#E76A4E", width: "100px", height: "100px", borderRadius: "50%", display: "flex", marginTop: "25px", justifyContent: "center", alignItems: "center" }}>
                                <img src={file} alt="Files(All Formate)" style={{ width: "50px", height: "50px" }} />
                            </div>

                            <h1 style={{ fontSize: "30px", textAlign: "justify" }}>Documents</h1>
                            <p style={{ textAlign: "justify", padding: "10px", width: "80%", fontSize: "25px" }}>bhAI is able to read, hear and see. Be it a long PDFs or a complex images, bhAI understands and responds accordingly.</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: isMobileOrTablet ? "100%" : "20%", backgroundColor: "#101010", margin: "10px", alignItems: "center", borderRadius: "30px", marginbottom: "100px" }}>
                            <div style={{ backgroundColor: "#E76A4E", width: "100px", height: "100px", borderRadius: "50%", display: "flex", marginTop: "25px", justifyContent: "center", alignItems: "center" }}>
                                <img src={file} alt="Files(All Formate)" style={{ width: "50px", height: "50px" }} />
                            </div>

                            <h1 style={{ fontSize: "30px", textAlign: "justify" }}>Images</h1>
                            <p style={{ textAlign: "justify", padding: "10px", width: "80%", fontSize: "25px" }}>bhAI automatically creates a website from your revealed messages. Simply choose what sections you want to share in your website
                                and bhAI will automatically update your website over time.</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: isMobileOrTablet ? "100%" : "20%", backgroundColor: "#101010", margin: "10px", alignItems: "center", borderRadius: "30px", marginbottom: "100px" }}>
                            <div style={{ backgroundColor: "#E76A4E", width: "100px", height: "100px", borderRadius: "50%", display: "flex", marginTop: "25px", justifyContent: "center", alignItems: "center" }}>
                                <img src={file} alt="Files(All Formate)" style={{ width: "50px", height: "50px" }} />
                            </div>

                            <h1 style={{ fontSize: "30px", textAlign: "justify" }}>Texts</h1>
                            <p style={{ textAlign: "justify", padding: "10px", width: "80%", fontSize: "25px" }}>Equip bhAI with an expertise like law or health to make it an expert in that field. While bhAI has knowledge about a lot of things,
                                equipping it with an expertise makes it much more precise and informed.</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: isMobileOrTablet ? "100%" : "20%", backgroundColor: "#101010", margin: "10px", alignItems: "center", borderRadius: "30px", marginbottom: "100px" }}>
                            <div style={{ backgroundColor: "#E76A4E", width: "100px", height: "100px", borderRadius: "50%", display: "flex", marginTop: "25px", justifyContent: "center", alignItems: "center" }}>
                                <img src={file} alt="Files(All Formate)" style={{ width: "50px", height: "50px" }} />
                            </div>

                            <h1 style={{ fontSize: "30px", textAlign: "justify" }}>Audios</h1>
                            <p style={{ textAlign: "justify", padding: "10px", width: "80%", fontSize: "25px" }}>Equip bhAI with an expertise like law or health to make it an expert in that field. While bhAI has knowledge about a lot of things,
                                equipping it with an expertise makes it much more precise and informed.</p>
                        </div>
                    </div>

                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};

const styles = {
    container: {
        fontFamily: "Helvetica",
        color: "white",
        backgroundColor: "#171717",
        paddingLeft: "150px",
        paddingRight: "150px",
        paddingTop: "50px",
        paddingBottom: "50px",
        textAlign: "justify",
    },
    heading: {
        fontSize: "50px",
        textAlign: "center",

    },
    paragraph: {
        fontSize: "25px",
        textAlign: "justify",
    },

};

export default Userguide;
