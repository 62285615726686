import React, { useState } from "react";
import Navbar from "../Components/Navbar"
import Footer from "../Components/Footer";

const Company = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        number: "",
        message: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data:", formData);
    };
    return (
        <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#171717" }}>
            <div style={{ display: "flex", height: "40px", display: "flex", justifyContent: "center", position: "relative" }}><Navbar />
            </div>
            <div style={styles.container}>

                <div style={styles.section}>

                    <div style={{ fontSize: "45px", textAlign: "center", marginBottom: "50px" }}>
                        <h1> About us</h1>
                    </div>
                    <h2 style={styles.heading}>Mission</h2>
                    <p style={styles.paragraph}>
                        Our mission is to free up time, energy and mind space for you. We take
                        care of the routine and the rudimentary so that you can do deep work.
                    </p>
                    <h2 style={styles.heading}>Vision</h2>
                    <p style={styles.paragraph}>
                        Our vision is to create intelligent interfaces. Computation has
                        evolved a lot over the last century. From physically rewiring machines
                        to punched cards to programming to graphics and touch, we have come a
                        long way. Tech has become much more accessible, easy to learn and easy
                        to when. But this is still not the best, when we integrate AI into the
                        interface itself, we eliminate the learning curve completely and make
                        tech as easy as it can be.
                    </p>

                    <h2 style={styles.heading}>Contact us</h2>
                    <div style={{ justifyContent: "center", display: "flex" }}>
                        <div style={styles.contactForm}>
                            <form style={styles.form} onSubmit={handleSubmit}>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    style={styles.input}
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    style={styles.input}
                                />
                                <input
                                    type="text"
                                    name="number"
                                    placeholder="Number"
                                    value={formData.number}
                                    onChange={handleChange}
                                    required
                                    style={styles.input}
                                />
                                <textarea
                                    name="message"
                                    placeholder="Message (Optional)"
                                    value={formData.message}
                                    onChange={handleChange}
                                    style={{ ...styles.textarea, ...{ height: "auto", minHeight: "120px", maxHeight: "500px" } }}
                                />
                                <button type="submit" style={styles.button}>
                                    Send Message
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};

const styles = {
    container: {
        fontFamily: "Helvetica",
        color: "white",
        backgroundColor: "#171717",
        textAlign: "justify",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "150px",
        paddingRight: "150px",
        paddingTop: "50px",
        paddingBottom: "50px",
    },

    section: {
        marginBottom: "40px",
    },
    heading: {
        fontSize: "50px",
        marginBottom: "30px",
        marginTop: "100px",
        textAlign: "center",
    },
    paragraph: {
        fontSize: "25px",
        marginBottom: "20px",
        textAlign: "justify"
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    input: {
        backgroundColor: "#333",
        color: "#fff",
        border: "1px solid #444",
        padding: "10px",
        marginBottom: "10px",
        borderRadius: "5px",
    },
    textarea: {
        backgroundColor: "#333",
        color: "#fff",
        border: "1px solid #444",
        padding: "10px",
        marginBottom: "10px",
        borderRadius: "5px",
        resize: "vertical",
        overflowY: "auto",
    },
    button: {
        backgroundColor: "#ff5722",
        color: "white",
        border: "none",
        padding: "10px",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "16px",
    },
    contactForm: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "60%",
        border: "1px solid #444",
        borderWidth: "3px",
        borderRadius: "5px",
        padding: "20px",
    },
};

export default Company;

