import React from 'react';
import imgGrp from '../assets/imgGrp.png';

const C6 = () => {
  return (
    <div className=" w-full justify-center mx-10 mt-20">
      <div className='items-center flex content-center flex-row justify-center mt-10' style={{ fontSize: "53px" }}>
        <p>Plugins make bh<span className='text-color'>AI </span>powerful</p>
      </div>
      <div className="justify-items-center flex w-[100%] overflow-hidden  flex-row ">
        <div className="flex justify-center w-[44%] float-left overflow-hidden ">
          <img src={imgGrp} alt="" width="100%" />
        </div>
        <div className="flex justify-center w-[50%] float-left font-montserrat  mx-4">
          <div className=" flex items-center font-montserrat">
            <div className="w-full ">
              <div className=" flex font-semibold mb-[2rem]" style={{ fontSize: "26px" }}>Discover a new way to interact with technology: </div>
              <p className="font-semibold m-0" style={{ fontSize: "26px" }}>Just tell bhAI what to do and it will be done.</p>
              <p className="font-semibold" style={{ fontSize: "26px", margin: 0 }}>Say bye bye to opening apps and software's.</p>
              <p className="font-semibold" style={{ fontSize: "26px", margin: 0 }}>bhAItakes actions on your behalf through plugins.</p>
              <div className='flex flex-row mt-[50px] justify-center '>
                <span className="w-[40%]" style={{ fontSize: "30px" }}>
                  <p className="m-0">• Gmail</p>
                  <p className="m-0">• Todo</p>
                  <p className="m-0">• Ledger</p>
                </span>
                <span className="w-[45%]" style={{ fontSize: "30px" }}>
                  <p className="m-0">• Notes </p>
                  <p className="m-0">• Information</p>
                  <p className="m-0">• Calendar</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default C6;
