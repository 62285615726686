import React from 'react';
import group338 from "../assets/group338.png";

const C4 = () => {
  return (
    <div className="w-full flex justify-center ">
      <div className="flex w-[90%] max-w-[1200px] justify-center flex-col ">
        <div className='text-center' style={{ fontSize: "53px", fontWeight: "600", marginBottom: "50px" }}>
          A truly Personal Assistant
        </div>
        <div style={{ backgroundImage: "linear-gradient(rgb(14, 14, 14, 1), rgb(23, 23, 23, 1))", padding: "50px", paddingBottom: "0", flexDirection: "row", display: "flex", borderRadius: "32px", justifyContent: "space-around" }}>
          <div className="flex w-[55%] align-middle">
            <p style={{ fontSize: "56px", fontWeight: "500", paddingLeft: "30px", paddingRight: "30px" }}>
              Over time, <span style={{ fontWeight: "600" }}>bh<sapn className='text-color'>AI</sapn></span> knows and learns about you and is able to craft responses for you.
            </p>
          </div>
          <div className="w-[35%]">
            <img src={group338} alt="" width="100%" />
            <div style={{ position: "relative", backgroundImage: "linear-gradient(rgba(28, 28, 28, 0),rgba(28, 28, 28, 1))", width: "100%", top: "-33%", height: "33%" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default C4;
