import C1 from "../Components/C1";
import Navbar from "../Components/Navbar";
import C4 from '../Components/C4';
import C6 from '../Components/C6';
import C7 from '../Components/C7';
import Control_Assistant from '../Components/Control_Assistant';
import Footer from '../Components/Footer';
import C11 from '../Components/C11';
import C13 from '../Components/C13';

const TerabhAI = () => {

  return (
    <div className="w-full relative ext-mini text-txt font-inter justify-center bg-bg flex flex-wrap overflow-hidden z-0">
      <Navbar />
      <C1 />
      <C4 />
      <C6 />
      <Control_Assistant />
      <C13 />
      <C7 />
      <C11 />
      <Footer />
    </div>

  );
};

export default TerabhAI;
