import React from 'react';
import C2 from './C2';
import phoneScreen from "../assets/phoneScreen.png"
import signu2p from '../assets/signu2p.png';

const C1 = () => {
    return (
        <div className="w-full flex relative justify-around p-[50px] flex-row mt-40 mb-20 ">
            <div className="flex w-[60%] flex-col justify-center">
                <div style={{ paddingLeft: "15%" }}>
                    <p style={{ fontSize: "56px", paddingLeft: "70px", paddingRight: "70px", fontWeight: "700", lineHeight: "0" }}>
                        Terabh<span className='text-color'>AI</span>
                    </p>
                    <p style={{ fontSize: "56px", paddingLeft: "70px", paddingRight: "70px", fontWeight: "500" }}>
                        Empower Your Productivity !
                    </p>
                    <p style={{ borderLeft: "2px solid rgba(171, 176, 191, 1)", paddingLeft: "70px", paddingRight: "70px", fontSize: "17px", lineHeight: "31px", color: "rgba(171, 176, 191, 1)" }}>Let bhAI tackle the routine, leaving you free to pursue your passions and potentially achieve greatness.</p>
                    <div style={{ padding: "70px" }}>
                        <C2 />
                    </div>
                </div>
            </div>

            <div style={{ display: "flex", width: "30%", marginTop: "-70px", flexDirection: "column", position: "relative" }}>
                <img src={phoneScreen} alt='' width="100%" />
                <img src={signu2p} width="100%" alt='' style={{ position: "absolute", marginTop: "2rem" }} />

                <div style={{ position: "absolute", backgroundImage: "linear-gradient(rgba(28, 28, 28, 0),rgba(28, 28, 28, 1))", width: "100%", top: "60%", height: "44%" }}></div>
            </div>
        </div>
    );
}

export default C1;
