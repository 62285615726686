import React from 'react';

const Footer = () => {
    const youtubeUrl = "https://www.youtube.com/";
    const instaUrl = "https://www.instagram.com/";
    const facebookUrl = "https://www.facebook.com/";
    const twitterUrl = "https://twitter.com/";
    const socialLinkHandler = (url) => {
        window.location.href = url;
    };
    return (
        <div className="relative w-full flex py-10 flex-col font-inter bg-bg" >
            <div className='text-center py-10 text-white' style={{ fontSize: "56px", fontWeight: "bold" }}>Terabh<span className='text-color'>AI</span></div>
            <div className='w-full flex  flex-row'>
                <div className='w-[50%] ml-[10%] flex-col flex '>
                    <div className="email flex justify-start" style={{ color: "white" }}>
                        <div className=" textIcons flex">
                            <i className="fa-solid fa-envelope" role="button"></i>
                        </div>
                        <div className="mx-5 flex justify-start" role="button">
                            <a
                                href="mailto:init.beyondcontrol@gmail.com"
                                style={{ color: "white", textDecoration: "none" }}
                            >
                                init.beyondcontrol@gmail.com
                            </a>
                        </div>
                    </div>
                    <div className="email flex justify-start flex-row my-4" style={{ color: "white" }}>
                        <div className=" textIcons flex">
                            <i className="fa-solid fa-location-dot " role="button"></i>
                        </div>
                        <div className="mx-5 flex justify-start" role="button">
                            Research and Entrepreneurship Park IIT Bhubaneswar
                        </div>
                    </div>
                </div>
                <div className='w-[50%] flow mt-5' style={{ color: "rgb(231 106 78 / var(--tw-text-opacity))" }}>
                    <div className="socialMediaLogo flex email text-color">
                        <div className="socialMediaIcon">
                            <i
                                className="fa-brands fa-youtube"
                                onClick={() => socialLinkHandler(youtubeUrl)}
                            ></i>
                        </div>
                        <div className="socialMediaIcon">
                            <i
                                className="fa-brands fa-instagram"
                                onClick={() => socialLinkHandler(instaUrl)}
                            ></i>
                        </div>
                        <div className="socialMediaIcon">
                            <i
                                className="fa-brands fa-facebook-f"
                                onClick={() => socialLinkHandler(facebookUrl)}
                            ></i>
                        </div>
                        <div className="socialMediaIcon">
                            <i
                                className="fa-brands fa-twitter"
                                onClick={() => socialLinkHandler(twitterUrl)}
                            ></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Footer;