import React from "react";
import Navbar from "../Components/Navbar"
import Footer from "../Components/Footer";

function TechnicalDetails() {
    return (<div style={{ display: "flex", flexDirection: "column", }}>
        <div style={{ display: "flex", height: "40px", backgroundColor: "#171717", display: "flex", justifyContent: "center", position: "relative" }}><Navbar />
        </div>
        <div style={styles.container}>
            <div style={{ fontSize: "45px", textAlign: "center", marginBottom: "50px" }}>
                <h1> Technical Details</h1>
            </div>
            <p style={{ fontSize: "25px", textAlign: "center", fontStyle: "italic" }}>
                LLM + LAM + RAG. That’s the technology behind TerabhAI. In this page, we
                will dig a bit deeper and understand what each of these are and how they
                were used to create bhAI.
            </p>
            <div style={{ display: "flex", marginTop: "160px", justifyContent: "center" }}>
                <h1 style={{ fontSize: "50px" }}>1. Large</h1>
                <h1 style={{ fontSize: "50px", color: "#E76A4E", paddingLeft: "5px", paddingRight: "5px" }}> Language </h1>
                <h1 style={{ fontSize: "50px" }}>Models</h1>
            </div>

            <p style={{ fontSize: "25px" }}>
                How do we go from neural networks to LLMs? The story starts with Recurrent
                Neural Networks - a special kind of neural nets which unfolds to generate
                sequence responses from a sequence. Think of translation or sentence
                completion. Roughly, each sentence is a sequence of words and your want
                your model to read the incomplete sequence and predict the remaining part
                which is also a sequence. href to the unreasonable effectiveness of RNNs.
                RNNs fail to have a long term memory and hence, they are typically upgraded
                to Long-Short Term Memory (LSTM). A kind of RNN which maintains a long
                term memory in top of the usual short term memory of the RNN. But think
                about when you are reading a sentence, do you maintain a long term and
                a short term memory? Or do you also pay attention to certain parts?
                That’s is what attention mechanism does.
            </p>
            <p style={{ fontSize: "25px" }}>
                In a seminal paper by google deepmind, they came up with a new architecture.
                The name of the paper gives a pretty good insight about the architecture -
                Attention is all you need. They dropped the recurrent unfolding nature and
                this new model only relied on attention. The key benefit was that this new
                model’s training was parallelizable. As you might have guessed, this model
                is called transformer which is the T in GPT.
            </p>
            <p style={{ fontSize: "25px" }}>
                The state of the art models, which bhAI uses behind the scenes, also go
                through a few more steps. They are trained on huge sets of data and have
                a lot of parameters. At various steps, new ”skills” are unlocked by the
                model. Starting from few shot learning (learning with examples only which
                did not live in train set) to zero shot learning to few shot learning
                to chain of thought reasoning and so on. To make the text completion
                model give more human like responses, the model also undergoes heavy
                fine tuning and reinforcement learning through human feedback. After all
                this work, you get LLMs. But that’s just the starting point

            </p>
            <div style={{ display: "flex", marginTop: "160px", justifyContent: "center" }}>
                <h1 style={{ fontSize: "50px" }}>2. Large</h1>
                <h1 style={{ fontSize: "50px", color: "#E76A4E", paddingLeft: "5px", paddingRight: "5px" }}> Action </h1>
                <h1 style={{ fontSize: "50px" }}>Models</h1>
            </div>

            <p style={{ fontSize: "25px", fontStyle: "italic", textAlign: "center" }}>
                Actions live at the heart of what makes bhAI such a powerful AI assistant.
            </p>
            <p style={{ fontSize: "25px" }}>
                LLMs are good at talking, but that’s not all you want a model to do. It should
                also be able to do tasks on your behalf. If you ask bhAI to write an email,
                wouldn’t it be great if bhAI could send the email with your consent right from
                there? This is where we need large action models which is just LLMs equipped with
                certain extra actions which it can take.
            </p>
            <div style={{ display: "flex", marginTop: "160px", justifyContent: "center" }}>
                <h1 style={{ fontSize: "50px" }}>3. Retrieval</h1>
                <h1 style={{ fontSize: "50px", paddingLeft: "5px", paddingRight: "5px" }}> Augmented </h1>
                <h1 style={{ fontSize: "50px", color: "#E76A4E" }}>Generation</h1>
            </div>
            <p style={{ fontSize: "25px", fontStyle: "italic", textAlign: "center" }}>
                RAG is used primarily for personal and revealed info, experts and handling large text data
            </p>
            <p style={{ fontSize: "25px" }}>
                Another key piece to building bhAI is RAG. An LLM/LAM have a natural bottleneck. There is
                only so much it can remember at once. So a lot of the information the user provides are
                stored in an embedding space. Think of it as a vector representation of a word in a
                space where vectors that are close by have similar word meanings. In this space, we
                can find which part of the total information is relevant. Instead of sending all
                the information we know and ask bhAI for a reply, we only send the relevant part.
                This process clears the bottleneck
            </p>

        </div>
        <div>
            <Footer />
        </div>
    </div>
    );
}
const styles = {
    container: {
        fontFamily: "Helvetica",
        color: "white",
        backgroundColor: "#171717",
        padding: "50px",
        textAlign: "justify",
        paddingLeft: "150px",
        paddingRight: "150px",
    },
};

export default TechnicalDetails;
