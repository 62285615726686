import React from "react";
import group339 from "../assets/group339.png";
import C2 from "./C2";

const C13 = () => {
  return (
    <div
      className="flex w-[90%] max-w-[1000px] justify-around flex-row align-middle"
      style={{
        backgroundImage:
          "linear-gradient(rgba(14, 14, 14, 1),rgba(23, 23, 23, 1))",
        borderRadius: "32px",
        padding: "40px",
        paddingBottom: 0,
      }}
    >
      <div className="flex w-[60%]" style={styles.leftTxt}>
        <div className="flex flex-col gap-4">
          The revealed assistant is the public face of your personal assistant.
          Context from messages pushed to revealed state are shared to the
          world. Anyone can chat with your revealed assistant and you can chat
          with anyone’s revealed assistant.
          <div className="w-[80%] mt-8">
            <C2 />
          </div>
        </div>
      </div>
      <div className="flex w-[30%]">
        <div className="w-full">
          <img src={group339} alt="phoneScreen" style={{ width: "100%" }} />
          <div
            style={{
              position: "relative",
              backgroundImage:
                "linear-gradient(rgba(28, 28, 28, 0),rgba(28, 28, 28, 1))",
              width: "100%",
              top: "-35%",
              height: "35%",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  leftTxt: {
    fontSize: "32px",
    padding: "40px",
  },
};

export default C13;
