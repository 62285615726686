import React from 'react';
import plus from '../assets/plus.svg';
import folder from '../assets/folder.svg';
import website from '../assets/website.svg';
import { useMediaQuery } from 'react-responsive';

const C7 = () => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 1224 });

  return (
    <div className="flex flex-row w-full justify-around mt-20 font-inter" style={{ paddingRight: "50px", paddingLeft: "50px", flexDirection: isMobileOrTablet ? "column" : "row" }}>
      <div style={{ width: isMobileOrTablet ? "100%" : "28%", backgroundColor: "rgba(23, 23, 23, 1)", display: "flex", flexDirection: "column", gap: "1rem", borderRadius: "30px", paddingTop: "50px", paddingBottom: "50px", marginBottom: isMobileOrTablet ? "20px" : "0" }}>
        <div className='flex justify-center'>
          <span style={{ padding: "20px", backgroundColor: "rgba(231, 106, 78, 1)", borderRadius: "20px" }}>
            <img src={folder} alt="" />
          </span>
        </div>
        <div style={{ paddingLeft: "60px", paddingRight: "60px", justifyContent: "center", display: "flex", flexDirection: "column" }}>
          <p style={{ fontSize: "26px", fontWeight: "600", textAlign: "center" }}>bh<span style={{ color: "rgb(231 106 78)" }}>AI</span> is not just about texts</p>
          <p style={{ textAlign: "center" }}>
            bhAI is able to read, hear and see. Be it a long PDFs or a complex images, bhAI understands and responds accordingly.
          </p>
        </div>
      </div>
      <div style={{ width: isMobileOrTablet ? "100%" : "28%", backgroundColor: "rgba(23, 23, 23, 1)", display: "flex", flexDirection: "column", gap: "1rem", borderRadius: "30px", paddingTop: "50px", paddingBottom: "50px", marginBottom: isMobileOrTablet ? "20px" : "0" }}>
        <div className='flex justify-center'>
          <span style={{ padding: "20px", backgroundColor: "rgba(231, 106, 78, 1)", borderRadius: "20px" }}>
            <img src={website} alt="" />
          </span>
        </div>
        <div style={{ paddingLeft: "60px", paddingRight: "60px", justifyContent: "center", display: "flex", flexDirection: "column" }}>
          <p style={{ fontSize: "26px", fontWeight: "600", textAlign: "center" }}>bh<span style={{ color: "rgb(231 106 78)" }}>AI</span> maintains your revealed website</p>
          <p style={{ textAlign: "center" }}>
            bhAI automatically creates a website from your revealed messages. Simply choose what sections you want to share in your website and bhAI will automatically update your website over time.
          </p>
        </div>
      </div>
      <div style={{ width: isMobileOrTablet ? "100%" : "28%", backgroundColor: "rgba(23, 23, 23, 1)", display: "flex", flexDirection: "column", gap: "1rem", borderRadius: "30px", paddingTop: "50px", paddingBottom: "50px" }}>
        <div className='flex justify-center'>
          <span style={{ padding: "20px", backgroundColor: "rgba(231, 106, 78, 1)", borderRadius: "20px" }}>
            <img src={plus} alt="" />
          </span>
        </div>
        <div style={{ paddingLeft: "60px", paddingRight: "60px", justifyContent: "center", display: "flex", flexDirection: "column" }}>
          <p style={{ fontSize: "26px", fontWeight: "600", textAlign: "center" }}> bh<span style={{ color: "rgb(231 106 78)" }}>AI</span> knows a lot!</p>
          <p style={{ textAlign: "center" }}>
            Equip bhAI with an expertise like law or health to make it an expert in that field. While bhAI has knowledge about a lot of things, equipping it with an expertise makes it much more precise and informed.
          </p>
        </div>
      </div>
    </div>
  );
};

export default C7;
